@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Bahnschrift';
  src: local('Bahnschrift'), url('fonts/Bahnschrift.woff2') format('woff2'),
    url('fonts/Bahnschrift.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
